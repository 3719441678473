import { Box } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import AddButton from 'common/components/buttons/addButton';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { GenericConfirmationCard } from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table from 'common/components/table/table-material';
import { ColumnsBelowSearch } from 'common/components/table/tableComponents';
import { limitStringSize } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getList, remove } from './validatorsActions';

export function ValidatorCode(props) {
  const { router } = props;
  const { list, loading } = props.validatorCode;
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [item, setItem] = useState(null);

  const MAX_LENGTH = 35;
  const defaultColumnsMobile = [
    {
      title: 'Identificação (nome, matrícula, ID)',
      field: 'identification',
      render: rowData =>
        rowData.identification
          ? limitStringSize(rowData.identification, MAX_LENGTH)
          : 'Sem identificação',
    },
    {
      title: 'Código',
      field: 'code',
      render: rowData =>
        rowData.code ? limitStringSize(rowData.code, MAX_LENGTH) : 'Sem código',
    },
    {
      field: 'actions',
      title: '',
      render: rowData => {
        return (
          <ColumnsBelowSearch
            buttons={{
              namedButtons: [],
              iconButtons: [
                {
                  name: 'delete',
                  onClick: () => {
                    setOpenConfirmation(true);
                    setItem(rowData.id);
                  },
                  color: '#E34848',
                },
              ],
            }}
          />
        );
      },
    },
  ];
  const defaultColumns = [
    {
      title: 'Identificação (nome, matrícula, ID)',
      field: 'identification',
      render: rowData =>
        rowData.identification
          ? limitStringSize(rowData.identification, MAX_LENGTH)
          : 'Sem identificação',
    },
    {
      title: 'Código',
      field: 'code',
      render: rowData =>
        rowData.code ? limitStringSize(rowData.code, MAX_LENGTH) : 'Sem código',
    },

    {
      field: 'actions',
      title: '',

      hasOnClick: true,
      render: rowData => {
        return (
          <ColumnsBelowSearch
            buttons={{
              namedButtons: [],
              iconButtons: [
                {
                  name: 'delete',
                  onClick: () => {
                    setOpenConfirmation(true);
                    setItem(rowData.id);
                  },
                  color: '#E34848',
                },
              ],
            }}
          />
        );
      },
    },
  ];

  const [columns] = useState(isMobile ? defaultColumnsMobile : defaultColumns);

  const [data, setData] = useState([]);

  useEffect(() => {
    props.getList(props.auth.user.establishments[0].id);
  }, []);

  useEffect(() => {
    setData(list.content);
  }, [list.content]);

  function onRowClick(e, rowData) {
    router.push(`/owner/validadores/${rowData.id}/editar`);
  }

  function handleDelete(id) {
    props.remove(id, props.auth.user.establishments[0].id);
    setOpenConfirmation(false);
  }

  function renderEmptyScreen() {
    return (
      <section id="statement-buy-points" style={{ height: '100vh' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <h2 className="signup-text">
                  Você ainda não adicionou validadores ligados ao seu CNPJ
                </h2>
                <p>Adicione dados para aparecer no seu site!</p>
                <PrimaryButton
                  onClick={() => props.router.push('/owner/validadores/novo')}
                >
                  Adicionar dados
                </PrimaryButton>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img alt="" src={placeholder_illustration} className="img-fluid" />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (list.content.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section id="validatorCode">
      <GenericConfirmationCard
        open={openConfirmation}
        loading={props.validatorCode.loading}
        general={props.general}
        title="Deletar Validador"
        confirmButtonColor={'primary'}
        text="Tem certeza? Você deletará o Validador"
        cancelClose={() => setOpenConfirmation(false)}
        onClose={() => setOpenConfirmation(false)}
        onClick={() => {
          if (item) handleDelete(item);
        }}
      />
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <Table
            hasToolbar
            toolbarSearch
            title="Validadores"
            columns={columns}
            rows={data}
            onRowClick={onRowClick}
            mobileTable={isMobile}
            hasBorder={!isMobile}
          />
        </GeneralGrid>
        <Box style={{ marginTop: 60, marginLeft: 10  }}>
          <AddButton
            onClick={() => props.router.push('/owner/validadores/novo')}
          />
        </Box>
      </GeneralGrid>
    </section>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    validatorCode: state.validatorCode,
    general: state.general,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getList,
      remove,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidatorCode);
