import types from './types';

const INITIAL_STATE = {
  loading: false,
  list: [],
  buttonLoading: false,
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'DRAFT_ACTIONS_LOAD':
      return { ...state, loading: action.payload };
    case 'DRAFT_ACTIONS_FETCHED':
      return { ...state, list: action.payload, loading: false };
    case 'DRAFT_ACTIONS_TOTAL_LOAD':
      return { ...state, loading: action.payload };
    case 'DRAFT_ACTIONS_TOTAL_FETCHED':
      return { ...state, draftActionsTotal: action.payload, loading: false };
    case types.DELETE_TEMPLATE_BUTTON_LOAD:
      return {
        ...state,
        buttonLoading: action.payload,
      };
    default:
      return state;
  }
};
